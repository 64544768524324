import { render, staticRenderFns } from "./LyvFooter.vue?vue&type=template&id=4d0e9fa6&scoped=true"
import script from "./LyvFooter.vue?vue&type=script&lang=js"
export * from "./LyvFooter.vue?vue&type=script&lang=js"
import style0 from "./LyvFooter.vue?vue&type=style&index=0&id=4d0e9fa6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d0e9fa6",
  null
  
)

export default component.exports